import * as React from "react"
import { useState } from 'react'
import Sticky from "react-stickynode"
import Container from "../Container/Container"
import { ImMenu } from "react-icons/im"

type NavListProps = {
  children: JSX.Element | JSX.Element[],
}

function NavList({ children }: NavListProps) {
  return (
    <ul className="w-full flex justify-between">
      { children }
    </ul>
  )
}

type NavItemProps = {
  children: string,
  permalink: string
}

function NavItem({ children, permalink }: NavItemProps) {
  return (
    <li>
      <a
        className="uppercase text-white text-sm px-4 font-semibold hover:text-secondary"
        href={permalink}
      >
        {children}
      </a>
    </li>
  )
}

function MobileNavItem({ children, permalink }: NavItemProps) {
  return (
    <li className="w-full">
      <a className="block p-4 w-full bg-black uppercase focus:bg-secondary" href={permalink}>
        {children}
      </a>
    </li>
  )
}

function Nav() {
  const [showMenu, setShowMenu] = useState(false)
  return (
    <div className="w-full flex justify-center">
      <div className="block w-full absolute top-0 lg:hidden">
        <div
          onClick={() => setShowMenu(!showMenu)}
          className="cursor-pointer flex w-full p-4 items-center justify-between"
        >
          <div className="uppercase font-bold">Menu</div>
          <div>
            <ImMenu />
          </div>
        </div>
        <ul className={`transition-all ${showMenu ? 'h-96' : 'h-0'} overflow-hidden`}>
          <MobileNavItem permalink="/#welcome">Welcome</MobileNavItem>
          <MobileNavItem permalink="/#about">About</MobileNavItem>
          <MobileNavItem permalink="/#services">Services</MobileNavItem>
          <MobileNavItem permalink="/#testimonials">
            Testimonials
          </MobileNavItem>
          <MobileNavItem permalink="/mini-excavator-hire">Hire</MobileNavItem>
          <MobileNavItem permalink="/#contact">Contact</MobileNavItem>
        </ul>
      </div>

      <Sticky
        className="w-full hidden lg:block"
        innerClass="transition-all"
        enabled={true}
        innerZ={50}
        innerActiveClass="w-full bg-black py-8"
      >
        <Container>
          <NavList>
            <NavItem permalink="/#welcome">Welcome</NavItem>
            <NavItem permalink="/#about">About</NavItem>
            <NavItem permalink="/#services">Services</NavItem>
            <NavItem permalink="/#testimonials">Testimonials</NavItem>
            <NavItem permalink="/mini-excavator-hire">Hire</NavItem>
            <NavItem permalink="/#contact">Contact</NavItem>
          </NavList>
        </Container>
      </Sticky>
    </div>
  )
}

export default Nav;
