import * as React from 'react'

type HeroProps = {
  id?: string,
  backgroundImage: string,
  children: JSX.Element | JSX.Element[],
}

function Hero({ id, backgroundImage, children }: HeroProps) {
  return (
    <div
      id={id}
      className="flex h-screen bg-cover px-4 py-4 md:py-24"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="w-full flex flex-col justify-between items-center">
        {children}
      </div>
    </div>
  )
}

export default Hero;
