import * as React from 'react'

type ContainerProps = {
  id?: string
  className?: string
  fullWidth?: boolean
  children: JSX.Element
}

function Container({
  id,
  className,
  fullWidth = false,
  children,
}: ContainerProps) {
  const classNames = ["mx-auto", "max-w-full", "px-4"]

  if (!fullWidth) {
    classNames.push("w-row")
  }

  if (className) {
    classNames.push(className)
  }

  return (
    <div id={id} className={classNames.join(" ")}>
      {children}
    </div>
  )
}

export default Container;
