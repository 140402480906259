import * as React from "react"
import Container from "../Container/Container"
import SimpleReactLightbox from "simple-react-lightbox"
import { ImInstagram, ImFacebook2 } from "react-icons/im"

type LayoutProps = {
  className: string,
  children: React.ReactNode
}

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <SimpleReactLightbox>
      <div className={`layout ${className}`}>
        <main>{children}</main>

        <footer>
          <Container className="pb-10">
            <div className="flex items-center justify-between">
              <p className="text-barely-visible text-sm">
                Copyright {new Date().getFullYear()}, All Rights Reserved
              </p>
              <p className="flex">
                <a
                  className="text-barely-visible transition-colors hover:text-white px-2"
                  href="https://www.facebook.com/saltpropertymaintenance"
                  target="_blank"
                >
                  <ImFacebook2 />
                </a>
                <a
                  className="text-barely-visible transition-colors hover:text-white px-2"
                  href="https://www.instagram.com/saltbuildinggroup/"
                  target="_blank"
                >
                  <ImInstagram />
                </a>
              </p>
            </div>
          </Container>
        </footer>
      </div>
    </SimpleReactLightbox>
  )
}

export default Layout
